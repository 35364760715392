.table {
    --bs-table-bg: #1f1f1f;
    --bs-table-border-color: #b4b4b4;
    --bs-table-striped-bg: #2c3034;
    --bs-table-striped-color: #fff;
    --bs-table-active-bg: #373b3e;
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: #323539;
    --bs-table-hover-color: #fff;
}

.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.fixTableHead {
    overflow-x: hidden !important;
    overflow-y: auto;
    max-height: 80vh;
}

.fixTableHead thead th {
    position: sticky;
    top: -1px;
}

table {
    border-collapse: collapse;
    width: 100%;
}

thead th {
    background-color: #353535 !important;
}

.form-check-input:disabled {
    opacity: 1;
}

.form-check-input {
        --bs-body-bg: #353535;
} 
.download-all {
    /* background-color: #353535; */
    color: #08ff08;
    max-width: 30%;
    border: none !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.download-all p {
    color: #08ff08;
}

.delete-all p {
    color: #ff0000;
}

/* width */
.download-page::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.download-page::-webkit-scrollbar-track {
    background: #353535;
}

/* Handle */
.download-page::-webkit-scrollbar-thumb {
    background: #FFF;
}

/* Handle on hover */
.download-page::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.search-box {
    position: relative;
    /* This allows absolute positioning of the icon */
    display: flex;
    /* Makes the icon and input elements sit side-by-side */
    align-items: center;
    /* Aligns the icon and input vertically */
    background-color: #353535;
    color: #FFF;
    padding: 5px 10px;
    border-radius: 35px;
    width: 50%;
}

.search-cancel-icon {
    margin-right: 10px;
}

.search-icon {
    font-size: 1.2rem;
    /* Adjust font size for the icon */
    
    /* Set the icon color (gray in this example) */
    padding: 5px;
    /* Add some padding around the icon */
    margin-right: 5px;
    /* Add a margin to separate the icon from the input */
}

.search-box img {
    background-color: #353535;
}

.search-box input {
    background-color: #353535;
    color: #FFF;
    width: 100%;
    /* Makes the input fill the remaining space */
    border: 1px solid #ddd;
    /* Adds a border to the input */
    padding: 5px 10px;
    /* Adds padding to the input */
    border-radius: 3px;
    /* Adds rounded corners to the input */
}

.search-box input:focus-visible {
    border: none;
    outline: none;
}

.download-username {
    width: 320px
}

@media only screen and (max-width:720px) {
    .download-username {
        width: 80px
    }
}

.username {
    text-decoration: none;
    color: #FFF !important;
}

.username:hover {
    text-decoration: none;
    color: #FFF !important;
}