.profile-img {
    width: 150px;
    height: 150px;
    object-fit: cover;
}

@media only screen and (max-width: 376px) {
    .profile-img {
        width: 125px;
        height: 125px;
    }
}