body {
  /* background-image: url("https://chat.teamlocus.com/public/images/background-3.png"); */
  background-color: #1b1b1b;
  /* background-color: #1f1f1f; */
  background-repeat: repeat;
}

a {
  color: teal !important;
  cursor: pointer;
}

@font-face {
  font-family: 'Qedysans';
  /* Name of your custom font */
  src: url('./assets//fonts/Qedysans-Normal.ttf') format('truetype');
  /* Path to your font file */
}

.circle {
  width: 50px;
  /* Adjust size as needed */
  height: 50px;
  /* Adjust size as needed */
  background-color: #007bff;
  /* Adjust background color */
  border-radius: 50%;
  /* Makes it a circle */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  /* Text color */
  font-size: 20px;
  /* Adjust font size as needed */
  font-family: 'Qedysans';
  /* Adjust font family */
  text-transform: uppercase;
  /* Optional: Make text uppercase */
  text-align: center;
  /* Center text horizontally */
  text-decoration: none;
  /* Remove default link underline */

  @media (max-width: 576px) {
    width: 40px;
    /* Decreased size for small devices */
    height: 40px;
    /* Decreased size for small devices */
    font-size: 16px;
    /* Decreased font size for small devices */
  }
}

.btn {
  font-size: 14px;
}

/* Media query for smaller devices */
@media (max-width: 576px) {
  .btn {
    font-size: 14px;
    /* Decreased font size for small devices */
  }

  .mobile_font_size {
    font-size: 10px;
  }

  .mobile_img_size {
    width: 10px;
  }
}
@media (max-width: 380px) {
  .mobile_font_size {
    font-size: 10px;
  }

  .mobile_img_size {
    width: 10px;
  }
}
@media (max-width: 850px) {
   .mobile_font_size {
    font-size: 10px;
  }

  .mobile_img_size {
    width: 10px;
  }
}

:root {
  --size-border: 0.8rem;
  --size-border-width: 0.2rem;
}

.icon {
  display: inline-block;
  color: white;
  opacity: 1;

  width: var(--size-border);
  height: var(--size-border);

  border-style: solid;
  border-color: white;
  border-width: var(--size-border-width) var(--size-border-width) 0 0;
}

.icon__previous {
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.icon__next {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.cursor-pointer {
  cursor: pointer;
}

.filter-invert {
  filter: invert(100%);
}

.font-bold {
  font-weight: bold;
}

/* For tablet devices: 5 items per row */
@media (min-width: 768px) {
  .container-fluid .d-flex {
    flex-wrap: wrap;
    justify-content: start;
  }

  .container-fluid .d-flex>div {
    width: calc(100% / 5 - 20px);
    max-width: 70px;
    margin-bottom: 20px;
  }
}

/* For laptop screens: 6 items per row */
@media (min-width: 992px) {
  .container-fluid .d-flex>div {
    width: calc(100% / 6 - 20px);
  }
}


/* Default style */
.search-ai-avatar {
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  vertical-align: middle;
  width: 300px;
  height: 300px;
}

/* Media query for mobile devices */
@media only screen and (max-width: 768px) {
  .search-ai-avatar {
    width: 40vw;
    /* Adjust to 30% of viewport width for mobile */
    height: 40vw;
    /* Adjust to 30% of viewport height for mobile */
  }
}

/* Media query for laptops */
@media only screen and (min-width: 769px) {
  .search-ai-avatar {
    width: 20vw;
    /* Adjust to 20% of viewport width for laptops */
    height: 20vw;
    /* Adjust to 20% of viewport height for laptops */
  }
}

.react-confirm-alert > .react-confirm-alert-body > h1 {
  font-size: 21px;
}

a {
  text-decoration: none !important;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.border-bottom, .border-top {
  border-color: #636363;
}