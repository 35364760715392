html,
body {
    height: 100%;
}

#root {
    height: 100%;
}

#root>section.bg-white,
#root>section.bg-white>.container,
#root>section.bg-white>.container>.row {
    height: 100%;
}